import * as React from "react";
import { IoLogoInstagram } from "react-icons/io";
import '../index.css';

const IndexPage = () => {
  return (
    <div className="bg-blue-50 flex flex-col items-center justify-around h-screen w-screen">
      <h1 className="text-6xl font-bold text-center text-pink-400 p-12 justify-self-center">
        Welcome to <span className="font-black">Caked By Design!</span>
      </h1>
      <div className="text-4xl text-center text-indigo-400 p-6 md:p-10 lg:p-12 max-w-prose leading-relaxed">
        <p className="font-light">
          We've got something baking in the oven, and can't wait for you to see it!
        </p>
        <p className="font-bold">
          Come back soon to find out more.
        </p>
      </div>
      <a href="https://www.instagram.com/cakedbydesign"
        className="text-6xl text-pink-400 transform hover:scale-110 hover:text-pink-500"
        aria-label="Instagram Logo"
      >
        <IoLogoInstagram />
      </a>
      <div className="text-xs text-center font-bold text-indigo-400 p-8">
        &copy; 2020 Caked By Design. All Rights Reserved.
      </div>
    </div>
  );
}

export default IndexPage;
